.build-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #1e1e1e;
  color: #ffffff;
}

.skill-tree {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.skill-category {
  background-color: #2a2a2a;
  padding: 10px;
  border-radius: 5px;
}

.skill-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.skill-icon {
  position: relative;
  cursor: pointer;
  background-color: #3a3a3a;
  border-radius: 5px;
  padding: 5px;
}

.skill-icon img {
  width: 50px;
  height: 50px;
  display: block;
}

.skill-level {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 2px 5px;
  font-size: 12px;
  border-top-left-radius: 5px;
}

.skill-details {
  width: 300px;
  padding: 20px;
  background-color: #2a2a2a;
  border-radius: 5px;
}

.level-controls {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.level-controls button {
  background-color: #4a4a4a;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
}

.build-summary {
  width: 200px;
  background-color: #2a2a2a;
  padding: 20px;
  border-radius: 5px;
}

.build-summary ul {
  list-style-type: none;
  padding: 0;
}

.build-summary li {
  margin-bottom: 5px;
}
