.ruleta-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  background-color: #1e1e1e;
  color: white;
  font-family: Arial, sans-serif;
}

.panel-control {
  width: 300px;
}

.tabs {
  display: flex;
  margin-bottom: 10px;
}

.tab {
  padding: 5px 10px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.tab.active {
  border-bottom-color: white;
}

.count {
  background-color: #333;
  border-radius: 10px;
  padding: 2px 6px;
  font-size: 0.8em;
  margin-left: 5px;
}

.options {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 10px;
}

.options button, .options label {
  background-color: #333;
  border: none;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.9em;
}

textarea {
  width: 100%;
  background-color: #333;
  color: white;
  border: none;
  padding: 10px;
  font-size: 14px;
}

.ruleta-wrapper {
  position: relative;
  width: 400px;
  height: 400px;
}

.ruleta {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  transition: transform 5s cubic-bezier(0.25, 0.1, 0.25, 1);
  cursor: pointer;
}

.segmento {
  position: absolute;
  top: 0;
  right: 50%;
  bottom: 50%;
  left: 0;
  transform-origin: 100% 100%;
  transform: rotate(calc(360deg / var(--total) * var(--index)));
  background-color: var(--color);
}

.nombre {
  position: absolute;
  left: 50%;
  top: 5%;
  transform: translateX(-50%) rotate(calc(-360deg / var(--total) / 2));
  transform-origin: center bottom;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.centro {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.flecha {
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 30px solid white;
}
