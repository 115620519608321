.lottery {
    text-align: center;
  }
  
  input[type="text"] {
    padding: 10px;
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .wheel {
    position: relative;
    width: 300px;
    height: 300px;
    margin: auto;
    border-radius: 50%;
  }
  
  .slice {
    position: absolute;
    width: 100%;
    height: calc(100% / var(--slices));
    transform-origin: bottom center;
    transform: rotate(var(--angle));
  }
  
  .slice span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
    font-size: 18px;
  }